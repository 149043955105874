<template>
    <div id="supervisor-sub-family">

        <b-modal hide-footer size="sm" ref="sub-family-modal" title="SUB-Family">
            <b-row>

                <b-col cols="12">
                    <b-form-group label="Family">
                        <v-select v-model="family" label="description" :options="families" placeholder="family" />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group label="SUB-Family Description">
                        <b-form-input v-model="subFamily.description" placeholder="SUB-Family" ref="subFamilyDescription"
                            @keyup.enter="saveData" />
                    </b-form-group>
                </b-col>

            </b-row>

            <b-row class="text-right">

                <b-col cols="12" md="12">
                    <b-button @click="saveData()" variant="relief-success">
                        <feather-icon icon="PocketIcon" class="mr-50" />
                        <span class="align-middle">Save</span>
                    </b-button>
                </b-col>

            </b-row>

        </b-modal>

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="SUB-Families List" @refresh="loadData" :add="true"
                    @plusClicked="showModal" @cellDoubleClicked="showModal" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import vSelect from 'vue-select'

export default {
    components: {
        AgGrid,
        vSelect
    },
    data() {
        return {
            families: [],
            family: {},
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Code",
                        field: "code",
                    },
                    {
                        headerName: "Description",
                        field: "description",
                    },
                    {
                        headerName: "Family",
                        field: "familyDescription",
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.showModal, icon: "read_more" },
                        width: 100
                    },
                ],
                rows: []
            },
            subFamily: {
                code: "",
                description: "",
                familyDescription: "",
                familyCode: "",
            },
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.loadData();
    },
    methods: {
        showModal(data) {
            if (data == undefined) {
                this.subFamily = {
                    code: "",
                    description: "",
                    familyDescription: "",
                    familyCode: "",
                };
                this.family = {};
            } else {
                if (data.code) {
                    this.subFamily = {
                        code: data.code,
                        description: data.description,
                        familyDescription: data.familyDescription,
                        familyCode: data.familyCode,
                    }
                    this.family = {
                        code: data.familyCode,
                        description: data.familyDescription,
                    };
                } else {
                    this.subFamily = {
                        code: data.data.code,
                        description: data.data.description,
                        familyDescription: data.data.familyDescription,
                        familyCode: data.data.familyCode,
                    }
                    this.family = {
                        code: data.data.familyCode,
                        description: data.data.familyDescription,
                    };
                }
            }
            this.$refs['sub-family-modal'].show();
            setTimeout(() => {
                this.$refs["subFamilyDescription"].focus();
            }, 400);
        },
        async saveData() {
            this.$refs['sub-family-modal'].hide();
            this.showLoading = true;
            this.subFamily.familyCode = this.family.code;
            this.subFamily.familyDescription = this.family.description;
            let response = await this.$http.post("_item/subFamily", this.subFamily).catch(() => this.showLoading = false);
            this.agGrid.rows = response.data;
            this.showLoading = false;
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("_item/subFamily").catch(() => this.showLoading = false);
            this.agGrid.rows = response.data;
            let families = await this.$http.get("_item/family").catch(() => this.showLoading = false);
            this.families = families.data;
            this.showLoading = false;
        }
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>